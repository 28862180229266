.style1 {
  margin-top: 0px;
  opacity: 1;
}

.style2 {}

.style3 {
  // background-image:url(../img/01.jpg)
  background-color: #dfca8f;
}

.morocco-back-ground {
  height: calc(100vh - 60px);
  width: 100%;
  cursor: pointer;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  position: relative;
}

.all-height-pizza {
  text-align-last: center;
  align-items: center;
  color: black;
  display: flex;
  height: 400px;
}

.all-height-fish {
  text-align-last: center;
  align-items: center;
  color: black;
  display: flex;
  height: 200px;
}

.all-width-pizza {
  opacity: 0.7;
  width: 100%;
  background-color: #dfca8f;
  font-style: italic;
  font-weight: bold;
}

.all-width-fish {
  opacity: 0.7;
  width: 100%;
  background-color: #dfca8f;
  font-style: italic;
  font-weight: bold;
  border-top: 10px solid green;
  border-bottom: 10px solid #142131;
}
.title-base {
  text-align-last: center;
  margin: 0;
  background: #dfca8f;
  padding: 20px;
}.title-base2 {
  text-align-last: center;
  margin: 0;
  background: white;
  padding: 20px;
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

.social-group {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    color: #dfca8f !important;
    height: 40px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  span {
    color: #2558a6;
    font-size: 28px;
    font-family: auto;
    font-weight: bold;
    text-shadow: 2px 2px 5px white;
  }
}

.meal {
  border-radius: 50%;
  width: 90px;
  height: 90px;

  background-repeat: round;
}

.paddin-meal {
  padding: 0 0px 0 25px;
}

.center-row {
  display: flex;
  justify-content: center;
}

.center-row-2 {
  display: flex;
  align-items: center;
  margin: 10px;
  background-color: #9b7819;
  box-shadow: 1px 1px 5px black;
}
.center-row-3 {
  display: flex;
  align-items: center;
  margin: 10px;
  background-color: white;
  box-shadow: 1px 1px 5px black;
  padding: 0 10px;
}

.tab-menu {
  justify-content: center;
}

.pizza-entree {
  padding: 10px;
}

.pizza-plat {
  padding: 10px;
}

.pizza-dessert {
  padding: 10px;
}

// .col-md-2 {
//   width: 8.33% !important;
// }
// .col-md-2 {
//   width: 16.66% !important;
// }
// .col-md-3 {
//   width: 25% !important;
// }
// .col-md-4 {
//   width: 33.33% !important;
// }
// .col-md-6 {
//   width: 50% !important;

//   @media (max-width: 768px) {
//     width: 100% !important;
//   }
// }
// .col-md-8 {
//   width: 66.66% !important;
// }
// .col-md-9 {
//   width: 75% !important;
// }
// .col-md-10 {
//   width: 83.33% !important;
// }
.separator {
  height: 20px;
  width: 100%;
  border-top: 2px solid #c5c8cb;
}

.width-100px {
  width: 100px !important;
}

.center-tab {
  display: flex;
  justify-content: center;
}

.cursor {
  cursor: pointer;
}

.pizza-content {}

.fish-image {
  height: 100px;
}

.fish-title {
  height: 30px;
  border-bottom: 1px #dfca8f dashed;
  color: #dfca8f !important;
}

.fish-desc {}

.fish-portfolio-item {
  position: relative;
  margin: 1px -15px 0 -14px;
  padding: 0;
  height: 200px;
  margin: 5px;
  /* border: 4px solid #030303; */
  box-shadow: 1px 1px 5px #030303;
  text-align-last: center;
}

.pizza-yellow {
  background-color: #c5ca817a;
}

.pizza-green {
  background-color: #a0ca817a;
}

.pizza-red {
  background-color: #caa7817a;
}

.pizza-purple {
  background-color: #8186ca7a;
}

.fish-img-responsive {
  height: 100px;

  border-radius: 50%;
  width: 100px;
  border: 2px solid #babd8f;
}

.fish-content {}

.fish-title-sub-menu {
  height: 50px;
  height: 60px;
  font-size: 18px;
  /* border: 3px solid rgba(160, 95, 155, 0.25); */
  /* background-color: #573e2312; */
  font-weight: 700;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.footer-parallax-container {
  background-image: url("./img/background1.jpeg");

  // background-image: url("./img/background1.jpeg");
}

.morocco-container {
  background-image: url("./img/zellij5.jpeg");
}

.sqala-header-background {
  background-color: white;
  padding: 10px;
}

.sqala-logo {
  background-image: url("./img/sqalaLogo.png");
  // background-image: url("./img/tajine.png");
  background-repeat: no-repeat;
  height: 40px;
  background-size: contain;
  width: 100%;
}

.fish-price {
  position: absolute;
  right: 0;
  font-size: 17px;
  background-color: #dfca8f;
  font-weight: bold;
  color: #820d0d;
  padding: 10px;
  box-shadow: 1px 0px 5px black;
}

.moroccan-gate {

  &_flex {
    display: flex;
    background: #dfca8f;
    height: 40px;
    justify-content: center;
  }

  &_flex1 {
    display: flex;
    background: #dfca8f;
    height: 28px;
    justify-content: center;
    margin-bottom: -1;
  }

  &_header01 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9b7819;
  }

  &_bg {
    background: #9b7819;

    box-shadow: 1px 1px 10px #142131;
  }

  &__cercle {
    width: 90vw;
    height: 90vw;
    border-radius: 50%;
    background: #997614;
  }

  &_header10 {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #997614;
  }

  &_header {
    border-radius: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(#997614, #dfca8f);
    margin-bottom: -1px;

    @media (max-width: 767px) {
      border-radius: 20rem 20rem 0 0;
    }

    &_image {
      background-image: url("./img/sqalaLogo.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 71px;
      background-position: center;
      margin-top: -1px;
    }

  }

  &_header1 {
    border-radius: 0 0 170px 170px;
    width: 100%;
    height: 150px;
    background-color: #dfca8f;
  }

  &_header2 {
    width: 90%;
    height: 40px;
    background-color: #dfca8f;
    margin: -50px auto;
  }

  &_header3 {
    border-radius: 35px 35px 0 0;
    width: 95%;
    background-color: #dfca8f;
    margin: auto;
  }
}

.all-width-100 {
  width: 100%;
  background: #dfca8f;
}

.back-white {
  background-color: #dfca8f;
  box-shadow: 0px 0px 4px #000000;
}

.back-red {
  background-color: #dfca8f;
}

.back-black-opac {
  background: #ffffff8f;
}

.paddinTop {
  padding-top: 40px;
}

.Image1 {
  background-image: url("./img/Image1.jpg");
  width: 36%;
  margin: auto;
  height: 65%;
  background-size: contain;
  background-repeat: no-repeat;
}

.col-md-6 {
  // padding: 0;
}
.paddin0 {
  padding: 0 !important;
}

.marginTop20 {
  margin-top: 20px;
  border-top: 1px solid #9b7819;
}

.flex {
  display: flex;
  place-items: center;
}

.selectflag {
  border: 1px solid #3f6ab0;
  background: #416cb126;
  border: 1px solid #3f6ab0;
  background: #416cb126;
  color: #3f6ab0;
  font-weight: bold;
  padding: 1px 2px;
  font-size: 12px;
  height: 32px;

  &:focus-visible {
    outline: rgba(255, 255, 255, 0) auto 1px;
    border-bottom: 1px solid #262626;

    border-bottom: 1px solid #262626;
    border: 1px solid #262626 !important;
  }
}

.morocco-h3 {
  margin: 8px 0;
  font-size: 18px;
  font-weight: bold;

  color: white;
}

.morocco-p {
  margin-bottom: 12px;
  font-size: 12px;
  color: #cecaca;
}

.morocco-contact-us {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #cecaca;
  }
}

.morocco-container-p {
  background: #0000005e;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 2px white;
}