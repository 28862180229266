.style1 {
  margin-top: 0px;
  opacity: 1;
}

.style2 {}

.style3 {
  // background-image:url(../img/01.jpg)
  background-color: red;
}

.all-height {
  text-align-last: center;
  align-items: center;
  color: white;
  display: flex;
  height: 400px;
}

.all-width {
  width: 100%;
  background-color: black;
  opacity: 0.7;
}

.title-base {
  text-align-last: center;
  margin: 20px 0;
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

.social-group {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meal {
  border-radius: 50%;
  width: 70px;
  height: 70px;

  background-repeat: round;
}

.paddin-meal {
  padding: 0 0px 0 25px;
}

.center-row {
  display: flex;
  justify-content: center;
}

.center-row-2 {
  display: flex;
  align-items: center;
}

.tab-container {
  display: flex;
  justify-content: center;
}

.box-shadow {
  box-shadow: 1px 1px 5px black;
  margin: 0;
}

.box-shadow1 {
  box-shadow: 1px 1px 5px black;
  margin: 0;
  background: #031d63;
  padding: 5px 0;
}

.pricing-container {
  padding: 0;
  font-weight: bold;
  background: white;
  padding: 9px;
  border-radius: 21px;
  height: 32px;
  box-shadow: 1px 1px 5px black;
  width: 32px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.back-ground {
  height: calc(100vh - 60px);
  width: 100%;
  cursor: pointer;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-bottom: 4px solid #ffffff00;
}